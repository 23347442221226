import React from 'react'
import { Link } from 'gatsby'
import Tag from './tag'
import { formatDate } from '../helpers/dateHelper'
import { myLogueUrl } from '../helpers/urlHelper'

const AsidePost = props => {
  const posts = props.posts
  const contents = []
  for (let i in posts) {
    const tags = []
    for (let j in posts[i].postTags) {
      tags.push(
        <Tag key={j} tag={posts[i].postTags[j]} />
      )
    }
    const path = myLogueUrl(posts[i])
    contents.push(
      <li key={i} className="post__item--column--half d-flex flex-column">
        <Link to={path} className="post__thumb--column aside-img">
          <picture>
            <source src={posts[i].image.fluid.srcWebp} type="image/webp" />
            <img
              loading="lazy"
              src={posts[i].image.fluid.src}
            />
          </picture>
        </Link>
        <div className="post__wrap--column d-flex flex-column justify-content-between">
          <div className="post__group">
            <div className="tag-list">
              {tags}
            </div>
            <Link to={path} className="post__text"></Link>
            <h2 className="post__title--small">{posts[i].title}</h2>
          </div>
          <div className="post__group mb-3 d-flex justify-content-between align-items-end">
            <div className="post__date">{formatDate(new Date(posts[i].createdAt))}</div>
            <div className="c-btn">
              <Link to={path} className="btn-outline">続きを読む</Link>
            </div>
          </div>
        </div>
      </li>
    )
  }
  return (
    <aside className="aside">
      <div className="heading-sub">
        <h3>RELATED</h3>
        <p>関連記事</p>
      </div>
      <div className="post">
        <ul className="post__list d-md-flex justify-content-between">
          {contents.slice(0, 2)}
        </ul>
      </div>
    </aside>
  )
}

export default AsidePost
