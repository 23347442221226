export function myLogueUrl(post) {
  let path = '/my-logue'
  if (post.category) {
    if (post.category.category2) {
      path = path + `/${post.category.category2.category1.slug}` + `/${post.category.category2.slug}` + `/${post.category.slug}`
    } else if (post.category.category1) {
      path = path + `/${post.category.category1.slug}` + `/${post.category.slug}`
    } else {
      path = path + `/${post.category.slug}`
    }
  }
  return path + `/${post.slug}`
}
export default myLogueUrl